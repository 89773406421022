import React from "react"
import styles from "./styles.module.css"
import { Inner } from "src/components/shared/Layout"
import PriceCard from "../PriceCard"
import Wave from "src/components/shared/Wave"

export const Prices = ({ prices }) => (
  <>
    <section>
      <Inner>
        <div className={styles.Prices}>
          {prices && prices.map((price) => <PriceCard {...price} />)}
          <div className={styles.Wave}>
            <Wave />
          </div>
        </div>
      </Inner>
    </section>
  </>
)

export default Prices
