import React from "react"
import styles from "./tarifs.styles.module.css"
import Button from "src/components/shared/Button"
import Layout, { Inner } from "src/components/shared/Layout"
import SEO from "src/components/shared/seo"
import Typography from "src/components/shared/Typography"
import { useStaticQuery, graphql } from "gatsby"
import Prices from "../components/shared/Prices"
import Title from "../components/shared/Title"

const pricesQuery = graphql`
  query PricesTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "prices" } }) {
      html
      frontmatter {
        subtitle
        pricesList {
          name
          price
          info
          featured
          logo
          color
        }
      }
    }
  }
`

export const PricesTemplate = ({ preview, prices, subtitle, html }) => (
  <Layout>
    <Inner>
      <SEO title="Tarifs — Turtle Tonic — Aquabike • Aquagym • Leçon de natation" />
      <Title>Les tarifs</Title>
      <Typography.h2 className={styles.Subtitle}>{subtitle}</Typography.h2>

      <div className={styles.Buttons}>
        <Button href="/reservation">Réserver un cours</Button>
        <Button href="/contact" bordered className={styles.BorderedButton}>
          Une question ?
        </Button>
      </div>

      <Prices prices={prices} />

      {!!preview ? (
        <Typography.p primary>{html}</Typography.p>
      ) : (
        <div
          className={styles.Paragraph}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )}
    </Inner>

    {/* <Inner small>
      <ContactForm />
    </Inner> */}
  </Layout>
)

export default () => {
  const data = useStaticQuery(pricesQuery)
  const {
    markdownRemark: {
      frontmatter: { pricesList, subtitle },
      html,
    },
  } = data

  return <PricesTemplate subtitle={subtitle} prices={pricesList} html={html} />
}
