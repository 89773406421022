import PropTypes from "prop-types"
import React from "react"
import styles from "./styles.module.css"
import cx from "classnames"
import Star from "src/images/star.svg"

const PriceCard = ({ name, price, info, featured, logo, color }) => (
  <div
    className={cx(styles.Container, {
      [styles.Featured]: featured,
    })}
  >
    {featured && <div className={styles.FeaturedCard}>Le plus populaire</div>}
    <div
      className={styles.Card}
      style={{
        backgroundColor: color,
      }}
    >
      <h2 className={styles.Name}>
        {featured && <img className={styles.Star} src={Star} alt="⭐️" />}
        {logo && <img className={styles.Logo} src={logo} alt={name} />}
        {name}
      </h2>
      <div
        className={styles.Info}
        style={{
          color,
        }}
      >
        <span className={styles.Price}> {price}</span>
        <span className={styles.MoreInfo}> {info}</span>
      </div>
    </div>
  </div>
)

PriceCard.propTypes = {
  name: PropTypes.string,
  price: PropTypes.string,
  info: PropTypes.string,
  featured: PropTypes.string,
  logo: PropTypes.string,
  color: PropTypes.string,
}

export default PriceCard
